<template>
  <html lang="en" class="notranslate" translate="no">
  <!-- All translators -->
  <head>
    <meta name="google" content="notranslate" /> <!-- Just for google -->
  </head>
  <div class="app">
    <Menu :list="menuList"/>

    <body>
      <Loading :isLoading="this.isLoading" />
      <br>
      <br>
      <div class="container mx-auto">
        <div class="row">
          <br>
          <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
            <div class="row">
              <center>
                <p class="text-xl lg:text-2xl xl:text-3xl mt-5 mb-2" style="color: #82385b; font-weight: bold;">Agregar
                  contraseña</p>
              </center>
            </div>
          </div>
          <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
            <div class="row">
              <center>
                <p class="md:text-xl lg:text-xl xl:text-2xl" style="color: #6E6E6E;">
                  Te recomendamos que por seguridad, elegir una contraseña única que no uses para<br> 
                  conectarte a otras cuentas
                </p>
              </center>
              <center>
                <br>
              </center>
            </div>
          </div>
          <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
            <div class="flex">
              <div class="mx-auto">
                <form @submit="changePassword" class="px-8 pt-6 pb-8 mb-4" style="text-align: left;">
                  <div class="mb-5 w-full">
                    <label to="input_password" class="text-base text-cherryColor mb-2">Contraseña nueva:</label>
                    <div class="input-group">
                      <div class="">
                          <input
                            type="password"
                            class="shadow-md border-2 rounded-lg py-3 w-full px-3"
                            id="input_password"
                            name="input_password"
                            minlength="8"
                            maxlength="30"
                            v-model="newPassword"
                            required
                          />
                          <span
                            tabIndex="-1"
                            class="input-group-addon"
                            style="position:absolute;margin-left: -40px;margin-top: 15px;"
                            ><button
                              type="button"
                              tabIndex="-1"
                              v-on:click="changeIcoPassword()"
                            >
                              <div tabIndex="-1" id="divImageHidePasswordOn">
                                <img
                                  id="imageHidePasswordOn"
                                  src="@/assets/visibility-off.svg"
                                  width="20"
                                  height="20"
                                  style="margin-right: 18px"
                                />
                              </div>
                              <div
                                tabIndex="-1"
                                id="divImageHidePasswordOff"
                                style="visibility: collapse"
                              >
                                <img
                                  tabIndex="-1"
                                  id="imageHidePasswordOff"
                                  src="@/assets/visibility-on.svg"
                                  width="0"
                                  height="0"
                                  style="margin-right: 18px"
                                />
                              </div>
                            </button>
                          </span>
                        </div>
                    </div>
                  </div>

                  <div class="mb-10">
                    <label to="input_password_confirm" class="text-base text-cherryColor mb-2">Verificar contraseña nueva:</label>
                    <div class="input-group">
                      <div class="">
                          <input
                            type="password"
                            class="shadow-md border-2 rounded-lg py-3 w-full px-3"
                            id="input_password_confirm"
                            name="input_password_confirm"
                            minlength="8"
                            maxlength="30"
                            v-model="confirmNewPassword"
                            required
                          />
                          <span
                            tabIndex="-1"
                            class="input-group-addon"
                            style="position:absolute;margin-left: -40px;margin-top: 15px;"
                            ><button
                              type="button"
                              tabIndex="-1"
                              v-on:click="changeIcoPasswordConfirm()"
                            >
                              <div
                                tabIndex="-1"
                                id="divImageHidePasswordConfirmOn"
                              >
                                <img
                                  id="imageHidePasswordConfirmOn"
                                  src="@/assets/visibility-off.svg"
                                  width="20"
                                  height="20"
                                  style="margin-right: 18px"
                                />
                              </div>
                              <div
                                tabIndex="-1"
                                id="divImageHidePasswordConfirmOff"
                                style="visibility: collapse"
                              >
                                <img
                                  tabIndex="-1"
                                  id="imageHidePasswordConfirmOff"
                                  src="@/assets/visibility-on.svg"
                                  width="0"
                                  height="0"
                                  style="margin-right: 18px"
                                />
                              </div></button
                          ></span>
                        </div>
                    </div>
                  </div>

                  <center>
                    <button class="btn btn-primary btn-lg" type="submit" 
                      id="button_popups">Cambiar contraseña
                    </button>

                  </center>
                </form>
                <center class="">
                  <button class="btn btn-primary btn-lg" type="button" 
                    id="button_popups" v-on:click="redirectLogin()">Cancelar</button>
                </center>
                <br>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>

  <Footer />

  </html>
</template>


<script>
import Menu from '../components/Menu.vue';
import Footer from '../components/Footer3.vue';
import Loading from '@/components/Loading/VueLoading.vue';
import Swal from "sweetalert2";

import { ref, onBeforeMount } from 'vue';
import menuOptions  from "@/helpers/menu.js";

export default {
  setup() {
    let menuList = ref([]);

    onBeforeMount(async () => {
      await getMenuOptionsList();
    });

    const getMenuOptionsList = async () => {
      await menuOptions.getMenuOptions().then(resp => {
        menuList.value = resp.data.FONCABSA;
      }).catch(err => {
        console.log("🚀 ~ file: Home.vue:223 ~ getMenuOptions ~ err", err);
      });
    };

    return {
      menuList,
      errors: [],
      apiToken: null,
      newPassword: "",
      confirmNewPassword: "",
      newPasswordHide: false,
      confirmNewPasswordHide: false,
      isLoading: false,
      url: process.env.VUE_APP_URLWEB,
      ccapi: process.env.VUE_APP_CCAPI
    };
  },
  name: 'CambiarContraseña',
  components: {
    Menu,
    Footer,
    Loading
  },
  props: {
    msg: String
  },
  methods: {
    changeIcoPassword() {
      if (this.passwordHide) {
        document
          .getElementById("input_password")
          .setAttribute("type", "password");
        document.getElementById("divImageHidePasswordOff").style.visibility =
          "collapse";
        document.getElementById("imageHidePasswordOff").width = "0";
        document.getElementById("imageHidePasswordOff").height = "0";
        document.getElementById("divImageHidePasswordOn").style.visibility =
          "visible";
        document.getElementById("imageHidePasswordOn").width = "20";
        document.getElementById("imageHidePasswordOn").height = "20";
        this.passwordHide = false;
      } else {
        document.getElementById("input_password").setAttribute("type", "text");
        document.getElementById("divImageHidePasswordOn").style.visibility =
          "collapse";
        document.getElementById("imageHidePasswordOn").width = "0";
        document.getElementById("imageHidePasswordOn").height = "0";
        document.getElementById("divImageHidePasswordOff").style.visibility =
          "visible";
        document.getElementById("imageHidePasswordOff").width = "20";
        document.getElementById("imageHidePasswordOff").height = "20";
        this.passwordHide = true;
      }
    },
    changeIcoPasswordConfirm() {
      if (this.confirmPasswordHide) {
        document
          .getElementById("input_password_confirm")
          .setAttribute("type", "password");
        document.getElementById(
          "divImageHidePasswordConfirmOff"
        ).style.visibility = "collapse";
        document.getElementById("imageHidePasswordConfirmOff").width = "0";
        document.getElementById("imageHidePasswordConfirmOff").height = "0";
        document.getElementById(
          "divImageHidePasswordConfirmOn"
        ).style.visibility = "visible";
        document.getElementById("imageHidePasswordConfirmOn").width = "20";
        document.getElementById("imageHidePasswordConfirmOn").height = "20";
        this.confirmPasswordHide = false;
      } else {
        document
          .getElementById("input_password_confirm")
          .setAttribute("type", "text");
        document.getElementById(
          "divImageHidePasswordConfirmOn"
        ).style.visibility = "collapse";
        document.getElementById("imageHidePasswordConfirmOn").width = "0";
        document.getElementById("imageHidePasswordConfirmOn").height = "0";
        document.getElementById(
          "divImageHidePasswordConfirmOff"
        ).style.visibility = "visible";
        document.getElementById("imageHidePasswordConfirmOff").width = "20";
        document.getElementById("imageHidePasswordConfirmOff").height = "20";
        this.confirmPasswordHide = true;
      }
    },
    changePassword(e) {
      e.preventDefault();
      this.isLoading = true;

      if (!this.validatePassword()) {
        this.isLoading = false;
        return;
      }
      var referencia = this.getParameterByName('referencia');

      if (referencia != "") {
        let data = {
          reset_password_token: referencia,
          password: this.newPassword,
          confirm_password: this.confirmNewPassword
        };
        this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/account/password/set`, data,
          {
            'Content-Type': 'application/json'
          })
          .then(response => {
            this.isLoading = false
            Swal.fire({
              title: "Aviso",
              text: "Se cambió la contraseña correctamente",
              icon: "success",
              confirmButtonColor: '#FEB72B',
            }).then(function () {
              window.location.href = "/login";
            });

          }).catch(error => {
            this.isLoading = false
            if (error.response.data.errores != undefined) {
              if (error.response.data.errores == "es inválido") {
                Swal.fire({
                  title: "Aviso",
                  text: "Reset_password_token" + error.response.data.errores,
                  icon: "error",
                  confirmButtonColor: '#FEB72B',
                })
              } else {
                Swal.fire({
                  title: "Aviso",
                  text: error.response.data.errores,
                  icon: "error",
                  confirmButtonColor: '#FEB72B',
                })
              }
            } else {
              Swal.fire({
                title: "Aviso",
                text: "Cambio de contraseña invalido",
                icon: "error",
                confirmButtonColor: '#FEB72B',
              })
            }
          });
      } else {
        this.isLoading = false
        Swal.fire({
          title: "Aviso",
          text: "Cambio de contraseña invalido",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        })
      }
    },
    getParameterByName(name) {
      name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
      var regexS = "[\\?&]" + name + "=([^&#]*)";
      var regex = new RegExp(regexS);
      var results = regex.exec(window.location.href);
      if (results == null)
        return "";
      else
        return decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    validatePassword() {
      this.errors = [];
      if (this.newPassword.includes(" ") == true || this.confirmNewPassword.includes(" ") == true) {
        this.errors.push("La contraseña no puede contener espacios")
        Swal.fire({
          title: "Aviso",
          text: this.errors,
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        })
        return false;
      }

      if (this.newPassword != this.confirmNewPassword) {
        this.errors.push("Las contraseñas son diferentes")
        Swal.fire({
          title: "Aviso",
          text: this.errors,
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        })
        return false;
      }
      return true;
    }
  }
}
</script>

<style scoped>
#panel {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#button_popups {
  border-radius: 5px !important;
  border: 1px solid #FFCC00;
  background-color: #FFCC00;
  padding-top: 0%;
  color: #fff;
  font-family: Roboto !important;
  font-size: 16px !important;
  width: 300px;
  height: 40px;
}

button {
  outline: none !important;
}
</style>